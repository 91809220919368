
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.container {
		max-width: 1190px;
		padding: 0;
	}
	.nav-scroller .nav-actions-bar {
		margin-left: 0px;
	}
	.profile-cover {
	    height: 320px;
	}
	.profile-picture {
	    height: 210px;
	    width: 210px;
	}
	.profile-picture-edit {
		position: absolute;
		top: 15px;
		right: 15px;
	}
	#stickThis.stick {
	    max-width: 297px;
	    padding-right: 0!important;
        padding-left: 0!important;
	}
	.mobile-hide {
		display: initial;
	}
}