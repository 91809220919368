
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	body {
		padding-bottom: 0;
	}
	html,
	body {
		/*overflow-x: inherit;*/
		max-width: 100%;
	}
	.container {
	    max-width: 960px;
	}
	.navbar-expand-md .navbar-nav .nav-link {
	    padding-right: 14px;
	    padding-left: 14px;
	}
	.menucard .media-body .menu-right {
		text-align: right;
	}
	.profile-picture {
		border: 5px solid #fff;
	    height: 200px;
	    width: 200px;
	}
	.profile-picture-edit {
		position: absolute;
		top: 8px;
		right: 8px;
	}
	.sticky-top,
	.sticky-offset-subnav {
		position: sticky;
	}
	#stickThis.stick {
	    max-width: 311.98px;
	    padding-right: 0!important;
        padding-left: 0!important;
	}
	#notification_modal .modal-content {
		max-height: 90vh;
		overflow-x: scroll;
	}
	.nugl-nav form.search {
	    float: left;
	    height: 33px;
	    margin: 0 15px 0 0;
	    font-size: 12px;
	    width: 221px;
	}
	.nav-scroller .nav-actions-bar {
		margin-left: 0px;
	}

	select,
	textarea,
	input { 
		font-size: inherit!important;
	}

	.nugl-nav .ais-InstantSearch__root {
		margin-left: initial;
		margin-right:  initial;
		width: initial;
	}
  
	.nugl-nav .search-container {
		padding: 0;
		position: relative;
		left: auto;
		right: auto;
		width: auto;
	}
	.nugl-topbar {
		position: absolute;
		left: 50%;
		margin-left: -12px;
	}
	.usercard .usercard-details p {
		margin-bottom: 2px;
	}
	.dealcard .dealcard-details p {
		line-height: 1.5;
		margin-bottom: 2px;
	}
	.signin-left {
		height: calc(100vh - 46px);
		min-height: 260px;
		padding: 0;
	}
	.signin-right {
		height: calc(100vh - 46px);
		min-height: 260px;
		padding: 0;
	}
	.nuglmd-left,
	.nuglmd-right {
		height: auto;
		padding: 0;
	}
	.search-dropdown-menu {
		top: 35px !important;
		left: -10px !important;
  	}
}
