
.nav-scroller {
  background-color: #1c2938;
  border-top: 1px solid #38444d;
  height: 60px;
  z-index: 2;
  overflow-y: hidden;
}
.nav-scroller .profilecard {
padding: 6px 15px;
}
.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  height: 59px;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.nav-scroller .nav-actions-bar {
  margin-left: 110px;
}

.nav-underline .nav-link {
  padding: 20px .5rem 15px;
  font-size: 10px;
  font-weight: 700;
  color: #8899a6!important;
  border-bottom: 2px solid transparent;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.nav-underline .nav-link svg {
  color: #8899a6!important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.nav-underline .nav-link:hover {
  color: #00A1E4!important;
  border-color: #00A1E4;
}
.nav-underline .nav-link:hover svg {
  color: #00A1E4!important;
}
.nav-underline .nav-link.active {
  color: #fff!important;
  border-bottom: 2px solid #00A1E4;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.nav-underline .nav-link.active svg {
  color: #fff!important;
}

.nav-button {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 59px;
  padding: 13px 0 0 2px;
  position: absolute;
  right: 15px;
  top: 50px;
}

.nav-button button.btn-outline { 
  background-color: transparent;
  border: 1px solid #8899a6;
  color: #fff;
}
.nav-button button.btn-outline:hover { 
  background-color: transparent;
  border: 1px solid #8899a6;
  color: #00A1E4;
}

.nav-underline .nav-button {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 59px;
  padding: 13px 0 0 2px;
  position: absolute;
  right: 0;
  top: 0;
}
.nav-underline button.btn-outline { 
  background-color: transparent;
  border: 1px solid #8899a6;
  color: #fff;
}
.nav-underline button.btn-outline:hover { 
  background-color: transparent;
  border: 1px solid #8899a6;
  color: #00A1E4;
}
.nav-underline button.btn-outline.disabled:hover { 
  color: #8899a6;
}

.nugl-nav {
	padding: 0;
	height: 46px;
	background-color: #1c2938;
}
.nugl-nav .container {
	height: 100%;
}
.dropdown-menu {
	top: 46px;
	font-size: 14px;
}
.nugl-nav .dropdown-menu-right {
	right: -4px;
}
.nugl-nav .dropdown-menu .dropdown-caret {
    position: absolute;
    top: -10px;
    left: auto;
    right: 10px;
    width: 18px;
    height: 10px;
    float: left;
    overflow: hidden;
}
.nugl-nav .dropdown-menu .dropdown-caret.dropdown-caret-left {
    position: absolute;
    top: -10px;
    right: auto;
    left: 10px;
    width: 18px;
    height: 10px;
    float: left;
    overflow: hidden;
}
.nugl-nav .dropdown-menu .dropdown-divider {
    padding-top: 1px;
    margin: 5px 1px 6px;
    border-top: 1px solid #e6ecf0;
}
.nugl-nav .notifications .dropdown-notifications .dropdown-item {
	padding: 8px 16px;
	margin-right: 20px;
}
.nugl-nav .dropdown-menu .dropdown-item,
.nugl-nav .dropdown-menu .dropdown-header {
	padding: 8px 16px;
}
.dropdown-header .float-right {
	font-weight: 400;
}
.nugl-nav .dropdown-menu .dropdown-item,
.nugl-nav .dropdown-menu .dropdown-item a {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #212529;
}
.nugl-nav .dropdown-menu .dropdown-item svg {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #6c757d;
}
.nugl-nav .dropdown-menu .dropdown-item.disabled,
.nugl-nav .dropdown-menu .dropdown-item.disabled a {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.nugl-nav .dropdown-menu .dropdown-item:hover a,
.nugl-nav .dropdown-menu .dropdown-item:focus a,
.nugl-nav .dropdown-menu .dropdown-item:hover svg,
.nugl-nav .dropdown-menu .dropdown-item:focus svg {
  color: #fff;
}
.nugl-nav .dropdown-menu .dropdown-item:hover,
.nugl-nav .dropdown-menu .dropdown-item:focus {
	background-color: #00A1E4;
    color: #fff;
}
.nugl-nav .dropdown-menu .dropdown-item i {
	color: #657786;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.nugl-nav .dropdown-menu .dropdown-item:hover i,
.nugl-nav .dropdown-menu .dropdown-item:focus i {
	color: #fff;
}
.nugl-nav .dropdown-caret .caret-outer {
    position: absolute;
    display: inline-block;
    margin-left: -1px;
    border-bottom: 10px solid #657786;
    border-bottom-color: rgba(0,0,0,0.1);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: auto;
    left: 0;
    top: 0;
    width: auto;
}
.nugl-nav .dropdown-caret .caret-inner {
    position: absolute;
    display: inline-block;
    margin-left: -1px;
    top: 1px;
    left: 1px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-bottom-color: #fff;
}
.nugl-nav .dropdown-account-header {
    display: block;
    width: 100%;
    padding: 4px 16px;
    clear: both;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.nugl-nav .dropdown-account-header a {
	color: #657786;
	font-size: 14px;
}
.nugl-nav .dropdown-account-header a:hover,
.nugl-nav .dropdown-account-header a:focus {
	color: #00A1E4;
}

.nugl-nav .ais-InstantSearch__root {
  margin-left: 60px;
  margin-right: 60px;
  width: 100%;
}

.nugl-nav .search-container {
  width: 100%;
}

.nugl-nav form.search {
    float: none;
    height: 33px;
    margin: auto;
    font-size: 12px;
    width: 100%;
}

#menuPosts form.search,
#feedPosts form.search,
#followingList form.search,
#searchResults form.search {
	margin-bottom: 0.5rem;
}
.nugl-nav .search-input {
	background-color: #f5f8fa;
	/*background-color: #182430;*/
    border-radius: 21px;
    border: none;
    /*border: 1px solid #38444d!important;*/
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
    /*color: #fff;*/
    display: block;
    font-size: 12px;
    height: 32px;
    line-height: 16px;
    padding: 8px 32px 8px 12px;
	    transition: all .2s ease-in-out;
    width: 100%;
}
.search-input.input-dark {
	background-color: #182430;
    border: 1px solid #38444d!important;
    color: #fff;
}

#menuPosts .search-input,
#feedPosts .search-input,
#followingList .search-input,
#searchResults .search-input {
    border-radius: 21px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    line-height: 1.5;
    padding: 8px 32px 8px 12px;
	    transition: all .2s ease-in-out;
    width: 100%;    
    font-size: .875rem;
    border: 1px solid #e6ecf0!important;
}
.nugl-nav .search-input::placeholder,
.form-control::placeholder {
	/*color: #66757f;*/
	color: #8899a6;
}
.nugl-nav .search-icon {
	cursor: pointer;
    display: block;
    height: 32px;
    position: absolute;
    right: 2px;
    top: 0px;
    width: 32px;
    padding: 9px;
}
#menuPosts .search-icon,
#feedPosts .search-icon,
#followingList .search-icon,
#searchResults .search-icon {
    cursor: pointer;
    display: block;
    height: 36px;
    position: absolute;
    right: 2px;
    top: 0px;
    width: 36px;
    padding: 8px;
}
.navbar-collapse {
	background: #1c2938;
}
.nugl-nav .search-icon .icon,
#menuPosts .search-icon .icon,
#feedPosts .search-icon .icon,
#followingList .search-icon .icon,
#searchResults .search-icon .icon {
    background-color: transparent;
    /*color: #66757f;*/
    color: #8899a6;
    height: auto;
    width: auto;
}
.notifications {
	width: 430px;
}

.dropdown-toggle::after {
    display: none;
}
.nugl-nav .user-avatar img {
	height: 32px;
	width: 32px;
	margin: 7px 0;
}
.nugl-nav .nav-item {
	font-size: 13px;
	font-weight: 700;
	position: relative;
}
.nugl-nav.navbar-dark .right-actions a.nav-link {
	color: #8899a6;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.nugl-nav.navbar-dark .right-actions a.nav-link svg {
	font-size: 32px;
}
.nugl-nav.navbar-dark .navbar-nav a.nav-link {
	color: #8899a6;
	height: 46px;
    padding: 13px 1rem;
	border-bottom: none;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.nugl-nav.navbar-dark .navbar-nav a.nav-link:hover {
	color: #00A1E4;
	border-color: #00A1E4;
}
.nugl-nav.navbar-dark .navbar-nav a.nav-link.active {
	color: #fff;
	border-bottom: 2px solid #00A1E4;
}
.nugl-nav.navbar-dark .navbar-nav a.nav-link.no-border {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}
.nugl-nav.navbar-dark .navbar-nav a.nav-link.no-border:hover {
	color: #00A1E4;
	border-color: transparent;
}
.nugl-nav.fixed-bottom .nav-item a.nav-link {
    color: #fff;
    line-height: 20px;
    font-size: 20px;
}
.nugl-nav.fixed-bottom .nav-item a.nav-link .badge-notification {
    position: absolute;
    top: 6px;
    left: 50%;
    font-size: 9px;
}

.nugl-topbar {
	position: absolute;
	z-index: 9;
}
.nugl-topbar img {
	height: 24px;
	width: 24px;
}
.nugl-topbar-right {
	right: 15px;
	margin-right: 0;
}
.nugl-topbar-left {
	left: 15px;
	margin-left: 0;
}




