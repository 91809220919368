body {
	background-color: #e6ecf0;
	padding-top: 46px;
	padding-bottom: 46px;
	font-size: 14px;
	/*overflow-x: hidden;*/
}
body.without-nav {
  padding: 0 !important;
}
html,
body {
	
	/*overflow-y: visible;*/
	max-width: 100%;
}

a {
	color: #00A1E4;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
a:hover,
a:focus {
	text-decoration: none;
}

a.gray-link {
	color: #657786;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
a.gray-link:hover,
a.gray-link:focus {
	color: #00A1E4;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	font-weight: 700;
}
p.disclaimer {
	font-size: 11px;
	line-height: normal;
}


.bg-gradient {
	width: 100%;
    height: 100%;
    animation: GradientAnimator 29s ease infinite;
    background: linear-gradient(280deg,#04e762,#00a1e4,#eb008b) center center/500% 500% no-repeat !important;
    background-color: #00A1E4;
}
@keyframes GradientAnimator {
	0% {
	    background-position: 0 50%;
	}
	50% {
	    background-position: 100% 51%;
	}
	100% {
	    background-position: 0 50%;
	}
}

.bg-gray {
    background-color: #EFF2F5!important;
}
.bg-lightgray {
	background-color: #f5f8fa!important;
}
.bg-lightgray .btn-link {
	color: #212529;
}
.bg-lightgray .btn-link:hover,
.bg-lightgray .btn-link:focus {
	color: #00A1E4;
	text-decoration: none;
	background-color: #fff;
}
.bg-lightblue {
	background-color: #E8F5FD!important;
}
.bg-white {
    background-color: #fff!important;
}
.btn {
	border-radius: 100px;
    box-shadow: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    white-space: nowrap;
}
.btn:focus,.btn:active,button:focus,button:active {
   outline: none !important;
   box-shadow: none;
}
.btn-sm {
    font-size: 12px;
    line-height: 16px;
    padding: 5px 12px;
	  min-width: 89px;
}
.btn-lg {
  padding: .75rem 1.5rem;
  font-size: 1rem;
}
.btn-blue {
	color: #fff;
	background-color: #00A1E4;
	border-color: #00A1E4;
}
.btn-blue:hover,
.btn-blue:focus {
	color: #fff;
	background-color: #0093D0;
	border-color: #0093D0;
}
.a-dark {
	color: #212529;
}
.a-med {
	color: #657786;
}
.a-dark:hover,
.a-dark:focus,
.a-med:hover,
.a-med:focus {
	color: #00A1E4;
}
.btn-link {
  color: #212529;
}
.btn-link:hover,
.btn-link:focus {
	color: #00A1E4;
	text-decoration: none;
	background-color: #f5f8fa;
}
.btn-link i {
	color: #657786;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-link:hover i,
.btn-link:focus i {
	color: #00A1E4;
}
.btn-outline { 
    background-color: transparent;
    border: 1px solid #8899a6;
    color: #212529;
}
.btn-outline:hover,
.btn-outline:focus { 
    background-color: transparent;
    border: 1px solid #8899a6;
    color: #00A1E4;
}
.btn-outline-gray {
	background-color: transparent;
	border: 1px solid #D2D7DB;
	color: #212529;
}
.btn-outline-gray:hover,
.btn-outline-gray:focus {
	background-color: transparent;
	border: 1px solid #D2D7DB;
	color: #00A1E4;
}
.btn-white-outline { 
    background-color: #fff;
    border: 1px solid #8899a6;
    color: #212529;
}
.btn-white-outline:hover,
.btn-white-outline:focus { 
    background-color: #fff;
    border: 1px solid #8899a6;
    color: #00A1E4;
}
.btn-pink {
    color: #fff;
    background-color: #EB008B;
    border-color: #EB008B;
}
.btn-pink:hover,
.btn-pink:focus {
    color: #fff;
    background-color: #D6007F;
    border-color: #D6007F;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
button {
	cursor: pointer;
}

#feedPosts .card .card-footer,
#reviewPosts .card .card-footer,
#menuPosts .card .card-footer {
	padding: 15px;
}
#feedPosts .card .card-body,
#reviewPosts .card .card-body,
#menuPosts .card .card-body,
#followingList .card .card-body,
#searchResults .card .card-body  {
	padding-bottom: 8px;
}
#feedPosts .card .card-body img,
#reviewPosts .card .card-body img {
	border-radius: 12px;
}

.card {
    background-color: #fff;
}
.card hr {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border: 0;
    border-top: 1px solid #e6ecf0;
}
.card.add-widget {
	background-color: transparent;
	color: #657786;
	text-align: center;
	min-height: 140px;
}
.card .card-body {
	padding: 15px;
}
.card .card-body .card-title {
	font-size: 18px;
	font-weight: 700;
}
.card .card-body .card-title .fa-stack {
  font-size: 14px;
}
.card .card-body p.card-title {
	font-size: 16px;
}
.card .card-body .card-title small {
	font-size: 12px;
	font-weight: 400;
}
.card-title-icons {
  margin: 0 5px;
}

.card .card-header {
	background-color: #f5f8fa;
	color: #657786;
	font-weight: 700;
}
.card .form-group {
	margin-bottom: 0.5rem;
}

.u-boxshadow {
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
}

.badge-notification {
	position: absolute;
	top: 8px;
	left: 8px;
	font-size: 9px;
}
.badge-gray {
	background-color: transparent;
	color: #657786;
}
.badge-gray:hover,
.badge-gray:focus {
	background-color: transparent;
	color: #657786;
}
.badge-pink {
	background-color: #EB008B;
	color: #fff;
}
.badge-pink:hover,
.badge-pink:focus {
	background-color: #EB008B;
	color: #fff;
}

button {
    background: transparent;
    border: 0;
    padding: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.chat-room-list {
  max-height: 200px;
  overflow-y: auto;
}

.chat-container {
  max-height: 530px;
  overflow-y: auto;
  padding: 10px;
}

.chat-row {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.chat-input {
  position: relative;
}

.chat-send-indicator {
  position: absolute;
  bottom: 50px;;
  left: calc(50% - 100px);
  border-radius: 4.8px;
  width: 200px;
  text-align: center;
  background-color: #00b894;
  color: #fff;
  opacity: .8;
  padding: 5px;
}

.chat-row.self {
  justify-content: flex-start;
}

.chat-row.other {
  justify-content: flex-end;
}

.chat-message-other {
  background-color: #EB0091;
}

.chat-message-self {
  background-color: #63B85B;
}

.chat-message {
  max-width: 80%;
  padding: 7px;
  border-radius: 2px;
  color: #FFE8F6;
}

.chat-timestamp {
  font-size: 10px;
  margin: 0 7px 0 7px;
}

.chat-message p {
  margin: 0;
}

.container-full {
	width: 100%;
	padding: 0 15px;;
}

.dashboard {
	padding-top: 10px;
}
.card-body .dashboard-nav {
	padding: .5rem 0;
	margin-right: -15px;
	margin-left: -15px;
}
.dashboard-nav {
	padding: .5rem 0;
}
.dashboard-nav li {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.dashboard-nav li:hover,
.dashboard-nav li.active {
	background-color: #f5f8fa;
    border-top: 1px solid #e6ecf0;
    border-bottom: 1px solid #e6ecf0;
}
.dashboard-nav li a {
	color: #212529;
}
.dashboard-nav li.active a,
.dashboard-nav li a:hover,
.dashboard-nav li a:focus {
	color: #00A1E4;
}
.dashboard-nav li a i,
.dashboard-nav li a svg {
	color: #657786;
}

.details-expander {
	position: relative;
	border-top: 1px solid #D2D7DB;
	margin: 20px 0;
}
.details-expander span {
	position: absolute;
	top: -10px;
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
	color: #8899a6;
}

.dropdown-notifications img {
	height: 36px;
	width: 36px;
}

.dropdown-suggestions img,
.dropdown-people img,
.dropdown-businesses img {
	height: 18px;
	width: 18px;
  margin-top: -3px;
  object-fit: cover;
}
.dropdown-item:hover,
.dropdown-item:focus {
	background-color: #00A1E4;
    color: #fff;
}
.dropdown-item i {
    color: #657786;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.dropdown-item:hover i, 
.dropdown-item:focus i {
    color: #fff;
}
.dropdown-item:first-child,
.dropdown-item:last-child {
	border-radius: 0!important;
}

.ekko-lightbox {
	background-color: rgba(0,0,0,0.5);
}
.ekko-lightbox .modal-content {
	background-color: transparent;
    border: none;
    border-radius: 0;
}

.fa-stack,
.fa-stack svg {
  line-height: 2em;
  vertical-align: middle;
  width: 2em!important;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.form-group {
  margin-bottom: 0.5rem;
  text-align: left!important;
}
.form-group label {
	margin-bottom: .25rem!important;
}
form.search {
    position: relative;
}

.post img.feed-user {
	height: 48px;
	width: 48px;
  max-height: 48px;
  object-fit: cover;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

 /* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.icon {
    background: transparent;
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    position: relative;
}
.icon-medium {
    font-size: 16px;
    line-height: 16px;
}

.input-button > input,
.image-upload > input,
.profile-cover-edit > input,
.profile-info-edit > input,
.profile-picture-edit > input,
.menu-picture-edit > input
{
    display: none;
}

select,
textarea,
input { 
  font-size: 16px!important;
}


.image-upload img
{
	width: 80px;
	cursor: pointer;
}
.profile-cover-edit span,
.profile-picture-edit span
{
	cursor: pointer;
}

.img-full {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.img-round {
	border-radius: 50%;
}

#profile_edit_dialog .modal-dialog,
#dm_dialog .modal-dialog {
	max-width: 750px;
    min-width: 0;
    overflow: hidden;
    position: relative;
    width: auto;
}
#dm_dialog .modal-body {
	height: 80vh;
    min-height: 100%;
    overflow: hidden;
}
#profile_edit_dialog .modal-body {
    min-height: 450px;
}
#dm_dialog .modal-body .dm_empty {
	margin: 48px;
}
#profile_edit_dialog .profile-cover {
	height: 175px;
	margin-bottom: 76px;
}
#profile_edit_dialog .profile-picture {
	height: 120px;
	width: 120px;
}
#profile_edit_dialog .profile-picture-edit {
	position: absolute;
	top: 0;
	right: 0;
}
#profile_edit_dialog .profile-picture-wrapper {
	bottom: 16px;
}
#profile_edit_dialog  .modal-body {
	overflow: scroll;
}

label {
  font-weight: 700;
  text-align: left!important;
}

.media-body {
	min-width: 0;
}

.dropdown-suggestions .close {
	margin-top: 4px;
}
.dropdown-notifications .close {
	margin-top: 18px;
}
.dropdown-notifications a {
	margin-right: 20px;
}
.dropdown-notifications a.unread {
	background: rgba(0, 161, 228, 0.1);
}
.dropdown-notifications .close,
.dropdown-suggestions .close,
.media-body .close {
	font-size: 14px;
}
.dropdown-notifications .close i,
.dropdown-suggestions .close i,
.media-body .close i {
	color: #ccd6dd!important;
}

.search-dropdown-menu {
  top: 8px !important;
  left: 0px !important;
  min-width: 100%;
}

a.mobile-nav-messages {
	color: #fff;
	position: absolute;
	right: 20px;
	font-size: 20px;
}
a.mobile-nav-messages .badge-notification {
	position: absolute;
	top: 0px;
	left: 10px;
	font-size: 9px;
}

.mobile-hide {
  display: none;
}

.modal-header .close {
    padding: 5px;
    margin: 0 0 0 10px;
    font-size: 20px;
}
.modal-header .modal-title {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 0;
}
.modal-body img {
	border-radius: 12px;
}


#photoWidget {
	position: relative;
}
.widget-overlay {
	position: absolute;
	background-color: rgba(0,0,0,0.7);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
}
.widget-overlay-inner {
	color: #fff;
	text-align: center;
	padding: 30px;
}
.widget-overlay-inner p {
	margin-bottom: 0;
	font-weight: 600;
}
#dealsWidget img,
#photoWidget img {
	border-radius: 4px;
}

.page-item.active .page-link {
    z-index: 1;
    color: #00A1E4;
    background-color: #fff;
    border-color: #dee2e6;
    font-weight: 500;
}

.pink-hover:hover,
.pink-hover:focus,
.btn-link.pink-hover:hover i,
.btn-link.pink-hover:focus i {
	color: #EB008B;
}

.pagination nav {
  margin: 10px auto;
}

.post .time,
.post .username {
	color: #657786;
}
.post .time:before {
    content: "\00b7";
}

.post-actions {
	padding: 6px 0
}

.profile-cover {
	height: 175px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 1px 1px rgba(0,0,0,0.25);
}
.profile-cover-home {
	border-top-right-radius: .25rem;
	border-top-left-radius: .25rem;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 95px;
	width: 100%;
}
.profile-cover-home img {
	border-top-right-radius: .25rem;
	border-top-left-radius: .25rem;
}
.profile-cover-edit {
	position: absolute;
	top: 56px;
	right: 10px;
}
.modal .profile-cover-edit {
	position: absolute;
	top: 20px;
	right: 20px;
}

.profile-footer {
	font-size: 12px;
	padding: .5rem 1rem;
}
.profile-footer a {
	color: #657786;
}
.profile-footer a:hover {
	color: #00A1E4;
}

.card .card-body.profile-info .card-title {
	font-size: 21px;
}

.profile-info-edit {
  cursor: pointer;
	position: absolute;
	top: 5px;
	right: 5px;
}
.profile-info-edit .dropdown-toggle::after,
.comment-options .dropdown-toggle::after,
.follow-options .dropdown-toggle::after {
	display: none;
}
.profile-info-edit .dropdown-menu {
	top: 40px;
	font-size: 14px;
}
.profile-info-edit .dropdown-menu .dropdown-caret {
    position: absolute;
    top: -10px;
    left: auto;
    right: 10px;
    width: 18px;
    height: 10px;
    float: left;
    overflow: hidden;
}
.profile-info-edit .dropdown-menu .dropdown-divider {
    padding-top: 1px;
    margin: 5px 1px 6px;
    border-top: 1px solid #e6ecf0;
}
.profile-info-edit .dropdown-menu .dropdown-item {
	padding: 8px 16px;
}
.profile-info-edit .dropdown-menu .dropdown-item:hover,
.profile-info-edit .dropdown-menu .dropdown-item:focus {
	background-color: #00A1E4;
    color: #fff;
}
.profile-info-edit .dropdown-caret .caret-outer {
    position: absolute;
    display: inline-block;
    margin-left: -1px;
    border-bottom: 10px solid #657786;
    border-bottom-color: rgba(0,0,0,0.1);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: auto;
    left: 0;
    top: 0;
    width: auto;
}
.profile-info-edit .dropdown-caret .caret-inner {
    position: absolute;
    display: inline-block;
    margin-left: -1px;
    top: 1px;
    left: 1px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-bottom-color: #fff;
}
.profile-info-edit .dropdown-account-header {
    display: block;
    width: 100%;
    padding: 4px 16px;
    clear: both;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.profile-info-edit .dropdown-account-header a {
	color: #657786;
	font-size: 14px;
}

.profile-picture {
    background: #fff;
    border: 3px solid #fff;
    border-radius: 50%;
    height: 110px;
    position: relative;
    width: 110px;
}
.post-input img,
.post-comment img {
	height: 36px;
	width: 36px;
	max-height: 36px;
}
.comment-container {
	padding: 8px 0;
	border-top: 1px solid #e6ecf0;
	margin-top: 8px;
}
.post-comment .comment-name {
	font-weight: 700;
}
.post-comment {
	margin-bottom: 15px;
}
.post-comment .comment-bubble {
	background-color: #f5f8fa;
    padding: 7px 12px 8px;
    border-radius: 20px;
}
.post-comment .comment-actions {
    padding: 2px 12px;
    font-size: 90%;
    color: #657786;
}
.post-comment .comment-reply {
    padding-left: 50px;
    margin-top: 10px;
}
.post-comment .comment-reply img {
	height: 20px;
    width: 20px;
    max-height: 20px;
    margin-top: 7px;
}

.comment-actions {
  padding: 2px 12px;
  font-size: 90%;
  color: #657786;
}

.comment-options {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.comment-options-dropdown {
  top: 20px !important;
  left: 45px !important;
}

.comment-options-ellipsis {
  position: absolute;
  left: 7.5px;
  top: 7.5px;
}

.menu-picture-edit {
	position: absolute;
	top: 5px;
	right: 12px;
}
.profile-picture-edit {
	position: absolute;
	top: 0px;
	right: 0px;
}
.profile-picture-wrapper {
	bottom: -77px;
    left: 10px;
    position: absolute;
    /*transition: bottom .2s;*/
    z-index: 3;
}
.profile-picture img {
	border-radius: 50%;
    height: 100%;
    max-height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 200px;
    object-fit: cover;
}

.profilecard {
	box-sizing: border-box;
    height: 60px;
    padding: 12px 15px;
    text-align: left;
}
suggested-card .card-body {
	padding-bottom: 0;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6ecf0;
	font-size: 13px;
}
.modal-header .usercard {
	padding: 0;
	min-height: 48px;
}
.dealcard,
.usercard {
	box-sizing: border-box;
    padding: 8px 0;
    text-align: left;
}
.usercard .usercard-about {
	padding: 10px 0;
}
.usercard .usercard-about p {
	max-height: 63px;
	overflow: hidden;
	margin-bottom: 0;
}
.menucard {
	box-sizing: border-box;
    padding: 8px;
    text-align: left;
}
.dealcard-container,
.menucard-container,
.usercard-container {
	border-bottom: 1px solid #e6ecf0;
	margin-left: -15px;
	margin-right: -15px;
	padding: 0 15px;
}
.usercard-container.unread {
    background: rgba(0, 161, 228, 0.1);
}
.notification-cards a.big-link,
.notification-cards a.big-link:hover,
.notification-cards a.big-link:focus {
	color: #212529;
}
.notification-cards .usercard-container:hover {
    background: #f5f8fa;
}
.notification-cards .unread.usercard-container:hover {
    background: rgba(0, 161, 228, 0.2);
}
#notification_modal .modal-content {
	max-height: auto;
	overflow-x: auto;
}
#notification_modal .modal-header {
	min-height: 81px;
}
/*.menucard:hover {
	background-color: #f5f8fa;
}*/
.menucard-container a,
.menucard-container a:hover,
.menucard-container a:focus {
	color: #212529;
	cursor: pointer;
}
.dealcard-container:last-child,
.menucard-container:last-child,
.usercard-container:last-child {
	border-bottom: none;
}
.dealcard .deal-image {
	height: 48px;
	width: 48px;
	border-radius: .25rem;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
.menucard .menu-image {
	height: 68px;
	width: 68px;
	border-radius: .25rem;
    border: 1px solid #f5f8fa;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
.profilecard .profilecard-picture,
.usercard .usercard-picture,
.menucard .menucard-picture,
.dealcard .dealcard-picture {
    margin-right: 0.5rem;
}
.profilecard .profilecard-picture img,
.usercard .usercard-picture img,
.menucard .menucard-picture img,
.dealcard .dealcard-picture img {
	height: 48px;
	width: 48px;
	max-height: 48px;
  object-fit: cover;
}
.user-card-home .usercard-picture img {
	border-radius: 50%;
    height: 72px;
    width: 72px;
    max-height: 72px;
    max-width: 72px;
    border: 2px solid #fff;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    object-fit: cover;
}

.profilecard .profilecard-details,
.usercard .usercard-details,
.menucard .menucard-details,
.dealcard .dealcard-details {
	line-height: 18px;
}
.profilecard .profilecard-details a.profilecard-title {
	color: #fff;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 700;
}
.usercard .usercard-details a.usercard-title,
.dealcard .dealcard-details a.dealcard-title,
.menucard .menucard-details a.menucard-title {
	color: #212529;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
}
.profilecard .profilecard-details a.profilecard-user,
.usercard .usercard-details a.usercard-user,
.usercard .usercard-details .usercard-user,
.usercard .usercard-details a.usercard-info,
.usercard .usercard-details .usercard-info,
.menucard .menucard-details .menucard-info,
.dealcard .dealcard-details .dealcard-info {
	color: #657786;
	font-size: 90%;
	margin-bottom: 0;
}
.menucard .menucard-details a.menucard-title:hover,
.menucard .menucard-details a.menucard-title:focus,
.dealcard .dealcard-details a.dealcard-title:hover,
.dealcard .dealcard-details a.dealcard-title:focus,
.usercard .usercard-details a.usercard-title:hover,
.usercard .usercard-details a.usercard-title:focus,
.usercard .usercard-details a.usercard-info:hover,
.usercard .usercard-details a.usercard-info:focus,
.profilecard .profilecard-details a.profilecard-user:hover,
.profilecard .profilecard-details a.profilecard-user:focus,
.usercard .usercard-details a.usercard-user:hover,
.usercard .usercard-details a.usercard-user:focus {
	color: #00A1E4;
}
.usercard .usercard-details p {
	margin-bottom: 2px;
}
.dealcard .dealcard-details p, 
.menucard .menucard-details p {
	line-height: 1.5;
	margin-bottom: 2px;
}
.menucard .media-body .menu-right,
.menucard .media-body .menu-left {
	text-align: left;
}
.menucard .media-body .menu-right {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.public-profile {
	padding-top: 10px;
}

.user-card-home .usercard .usercard-details a.usercard-title {
	font-size: 18px;
}

.user-card-home .progress {
  width: 72px;
  height: 72px;
  line-height: 72px;
  background: #fff;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  border-radius: 50%;
}
.user-card-home .usercard-picture {
	margin: -30px 8px 0;
}
.user-card-home.usercard-container {
    border-bottom: none;
}
.user-card-home .progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}
.user-card-home .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.user-card-home .progress .progress-left {
  left: 0;
}
.user-card-home .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #EB008B;
}
.user-card-home .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.user-card-home .progress .progress-right {
  right: 0;
}
.user-card-home .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.user-card-home .progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 700;
}
.user-card-home .progress .progress-value div {
  margin-top: 10px;
}
.user-card-home .progress .progress-value img {
	max-height: 54px;
	height: 54px;
	width: 54px;
}
.user-card-home .progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}



.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 10px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #EB008B;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 700;
}
.progress .progress-value div {
  margin-top: 10px;
}
.progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

/* This for loop creates the 	necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
.progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}
.progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}
.progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}
.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
}
.progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 0.5s linear forwards 0.5s;
}

.progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 0.5s linear forwards 0.5s;
}

.progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 0.5s linear forwards 0.5s;
}

.progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 0.5s linear forwards 0.5s;
}

.progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 0.5s linear forwards 0.5s;
}

.signin-footer {
	color: #aab8c2;
	font-size: 12px;
	height: 46px;
	border-top: 1px solid #e6ecf0;
    padding: 14px 0;
    background-color: #fff;
    width: 100%;
}
.signin-footer a {
	color: #aab8c2;
}
.signin-footer a:hover,
.signin-footer a:focus {
	text-decoration: underline;
}
.signin-left {
  min-height: 260px;
  padding: 15px 0;
}
.signin-left p {
	font-size: 18px;
	font-weight: 500;
	padding: 15px 0;
}
.nuglmd-left p {
  font-size: 14px;
  padding: 0;
}
.nuglmd-left,
.nuglmd-right {
  height: auto;
}
.signin-right {
	min-height: 260px;
	padding: 15px 0;
}

a.show-more-link {
  color: #00A1E4 !important;
  cursor: pointer;
}
.show-more-link:hover {
  color: #0056b3 !important;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}
.progress {
  margin-bottom: 1em;
}


.rating-stars .far {
	color: inherit;
}
.rating-stars a.empty-star {
	color: inherit;
}
.rating-stars .empty-star .fas,
.rating-stars .empty-star:hover .far {
	display: none;
}
.rating-stars .empty-star:hover .fas {
	display: inline;
}
.rating-stars .fas {
	color: #04E762;
}
.rating-stars .fas.fa-star:hover {
	color: #04E762;
}

.rotate{
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}

.rotate.down{
    -ms-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.sticky-offset-nav {
    top: 46px;
}
.sticky-offset-nav-space {
    top: 56px;
}
.sticky-top.sticky-offset-subnav {
    top: 114px;
    z-index: 1;
}

/*select.form-control {
	content: "f0dc";
	border-radius: 21px;
	-webkit-appearance: none;
}*/
.form-group.select-arrows {
	position: relative;
}
.form-group.select-arrows .arrows {
	position: absolute;
	right: 0;
	top: 0;
	font-family: "Font Awesome 5 Pro";
	content: "\f0dc";
}

.sponsored-block,
.sponsored-monster,
.sponsored-leaderboard {
	position: relative;
	margin-bottom: .5rem;
	text-align: center;
}
.sponsored-block img,
.sponsored-monster img {
	width: 100%;
	max-width: 300px;
}
.sponsored-leaderboard img {
	width: 100%;
	max-width: 728px;
}
.sponsored-block .sponsored-callout,
.sponsored-monster .sponsored-callout,
.sponsored-leaderboard .sponsored-callout {
	position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    padding: 4px 8px;
    font-size: 11px;
}

.search {
  width: 100%;
}

.search-magnifier {
  color: #8899a6;
  left: calc(100% - 25px);
  position: absolute;
  transition: 0.15s all;
}

.search-magnifier:hover {
  color: #007bff!important
}

.search-input {
  border-radius: 21px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  line-height: 1.5;
  padding: 8px 32px 8px 12px;
  transition: all .2s ease-in-out;
  width: 100% !important;
  font-size: .875rem;
  border: 1px solid #e6ecf0 !important;
}

.sticky-top,
.sticky-offset-subnav {
	position: initial;
	z-index: 2;
}

textarea {
	resize: none;
	border: 1px solid #e6ecf0!important;
}
textarea.expand {
    height: 38px;
}
.form-control {
	font-size: .875rem;
	border: 1px solid #e6ecf0!important;
	line-height: 1.5;
}
textarea.form-control {
	padding: .75rem 2rem .75rem .375rem;
}
.input-group-sm>textarea.form-control {
	padding: 7px 12px;
    border-radius: 20px;
}
.text-blue {
	color: #00A1E4;
}
.text-lightgray {
	color: #f5f8fa;
}
.text-gray {
	color: #ccd6dd;
}
.text-darkgray {
	color: #657786;
}
.text-green {
	color: #04E762;
}
.text-pink {
	color: #EB008B;
}

.tooltip {
	font-size: 12px;
}

.toggle-switch {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.toggle-switch .inner-switch {
  background: #fff;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 0.25rem;
  border-radius: 50%;
  transition: 0.15s left ease-in-out;
}

.toggle-switch .inner-switch.left {
  left: 0.25rem;
}

.toggle-switch .inner-switch.right {
  left: 1.75rem;
}

.toggle-switch.active {
  background-color: #04E762;
}

.medium,
medium {
	font-size: 90%;
}

.truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* ===== Toggles ===== */

.btn-toggle {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active {
  background-color: #04E762;
}
.btn-toggle.btn-lg {
  margin: 0 5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 2.5rem;
  width: 5rem;
  border-radius: 2.5rem;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
  outline: none;
}

.btn-toggle.btn-lg > .handle {
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-lg.active > .handle {
  left: 2.8125rem;
  transition: left 0.25s;
}

.btn-toggle.btn-sm {
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
  outline: none;
}
.btn-toggle.btn-sm > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.btn-sm.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-sm.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.btn-xs {
  margin: 0 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1rem;
  width: 2rem;
  border-radius: 1rem;
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
  outline: none;
}

.btn-toggle.btn-xs > .handle {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.btn-xs.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-xs.active > .handle {
  left: 1.125rem;
  transition: left 0.25s;
}
.btn-100 {
	width: 100px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker__time-container {
  width: 120px !important;
}

.react-datepicker__time-box {
  width: 100% !important;
}