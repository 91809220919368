/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.container {
	    max-width: 100%;
	}
	.menucard .media-body .menu-right {
		text-align: right;
	}
	#profile_edit_dialog .modal-dialog,
	#dm_dialog .modal-dialog {
	    min-width: 425px;
	    width: 45vw;
	}
	#dm_dialog .modal-body {
	    min-height: 450px;
	}
	.nav-underline .nav-link {
		padding: 8px 1rem 5px;
		font-size: 12px;
	}
	.nav-scroller .nav-actions-bar {
		margin-left: 140px;
	}
	.profile-picture {
	    height: 140px;
	    width: 140px;
	}
	.profile-picture-wrapper {
		bottom: -87px;
	}
	.sticky-top,
	.sticky-offset-subnav {
		position: initial;
	}
	#stickThis.stick {
		width: 33.333333%;
	    padding-right: .25rem!important;
        padding-left: .25rem!important;
	}
	.usercard .usercard-details p {
		margin-bottom: 2px;
	}
  .usercard .usercard-about {
    padding: 10px 0;
  }
  .usercard .usercard-about p {
    max-height: 63px;
    overflow: hidden;
    margin-bottom: 0;
  }
  .usercard-info {
    color: #657786;
    font-size: 90%;
    margin-bottom: 0;
  }
	.dealcard .dealcard-details p {
		line-height: 1.5;
		margin-bottom: 2px;
	}
}