
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {	
	.nav-scroller .nav-actions-bar {
		margin-left: 0px;
	}
	.nuglmd-left,
	.nuglmd-right {
		height: 100vh;
		min-height: 260px;
		padding: 0;
	}
	.profile-cover {
		height: 280px;
	}
	.profile-picture {
	    height: 200px;
	    width: 200px;
	}
	.profile-picture-edit {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	#stickThis.stick {
	    max-width: 311.98px;
	    padding-right: 0!important;
        padding-left: 0!important;
	}
}