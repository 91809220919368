/*!
* Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
* Copyright 2013-2019 Start Bootstrap
* Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
*/

#page-content-wrapper {
  min-width: 100%;
}

.page-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e6ecf0;
  opacity: 0;
  z-index: -9;
  -webkit-transition: opacity .15s ease-out;
  -moz-transition: opacity .15s ease-out;
  -o-transition: opacity .15s ease-out;
  transition: opacity .15s ease-out;
}

#sidebar-wrapper {
  display: block;
  min-height: 100vh;
  margin-left: -20rem;
  -webkit-transition: margin .15s ease-out;
  -moz-transition: margin .15s ease-out;
  -o-transition: margin .15s ease-out;
  transition: margin .15s ease-out;
  z-index: 5;
}

#sidebar-wrapper .dropdown-divider {
  margin: 0;
}
#sidebar-wrapper .dropdown-item {
  padding: 12px 16px;
  font-size: 16px;
}
#sidebar-wrapper .dropdown-item:hover,
#sidebar-wrapper .dropdown-item:focus {
  background-color: #00A1E4;
    color: #fff;
}
#sidebar-wrapper .dropdown-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
#sidebar-wrapper .dropdown-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
#sidebar-wrapper .dropdown-item i {
  color: #657786;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#sidebar-wrapper .dropdown-item:hover i,
#sidebar-wrapper .dropdown-item:focus i {
    color: #fff;
}


#sidebar-wrapper .list-group {
  width: 20rem;
}

#sidebar-wrapper .sidebar-content {
  font-size: 16px;
}
#sidebar-wrapper .sidebar-heading {
  font-size: 16px;
  padding: 0.875rem 1.25rem;
}
#sidebar-wrapper .sidebar-heading img {
  height: 48px;
  width: 48px;
  max-height: 48px;
}

#sidebar-wrapper .sticky-top {
  position: sticky;
  z-index: 2;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
#wrapper.toggled #sidebar-wrapper .list-group {
  overflow-y: scroll;
  max-height: calc(100vh - 225px);
}
#wrapper.toggled .page-overlay {
  opacity: 0.6;
  z-index: 4;
}